<template>
    <Modal size="small" class="modal modal--mob modal--paypal" @close="$emit('close')">

        <template #body>
            <div class="u-text-center">
                <img class="mb-" src="/img/payment_types/PAYPAL.svg" alt="">
                <h1><strong>{{ $t('payment.paypal.prepayment_notice') }}</strong></h1>
            </div>

            <h2 class="mb-">{{ $t('payment.paypal.add_credits') }}</h2>
            <p>{{ $t('payment.paypal.payment') }}</p>
            <template v-if="!confirmStep">
                <h2 class="u-text-left mb-">{{ $t('payment.paypal.your_order') }}</h2>
                <div class="u-text-left">
                    <p class="mb--">{{ $t('payment.paypal.package', {credits: selectedPackage.credits}) }}</p>
                    <p class="mb--">{{ $t('payment.paypal.price', {price: selectedPackage.price.toFixed(2), currency: selectedPackage.currency}) }}</p>
                    <p class="mb">{{ $t('payment.paypal.vat') }}</p>
                    <button type="button" @click="confirm" class="button button--primary w-100 mb--">{{ $t('payment.paypal.order_now') }}</button>
                    <button @click="$emit('close')" type="button" class="button button--fourth w-100">{{ $t('payment.paypal.cancel') }}</button>
                </div>
            </template>
            <template v-else>
                <div class="mt">
                    <p class="mb" v-html="$t('payment.paypal.please_transfer', {price: selectedPackage.price.toFixed(2), currency: selectedPackage.currency, email: '<strong>maiatsacoltd@protonmail.com</strong>'})"></p>
                    <p class="mb" v-html="$t('payment.paypal.additionally')"></p>
                    <p class="mb" v-html="$t('payment.paypal.ensure')"></p>
                    <button @click="$emit('close')" type="button" class="button button--primary w-100">{{$t('payment.paypal.close')}}</button>
                </div>
            </template>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/generic/Modal.vue";
export default {
    name: "PayPalConfirmModal",
    components: { Modal },
    emits: ['close', 'confirmed'],
    props: {
        selectedPackage: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            confirmStep: false,
            working: false
        }
    },
    methods: {
        confirm() {
            this.confirmStep = true
            this.$emit('confirmed')
        }
    }
}
</script>

<style scoped lang="scss">

.modal--paypal {
    text-align: center;

    p {
        line-height: 1.3;
        font-size: 14px;
    }
}

</style>

<style lang="scss">
    .modal--paypal {

        .modal-container {

            .modal-header {

                .close {
                    display: block!important;
                    top: 0!important;
                    right: 0!important;
                }
            }
        }
    }
</style>