import store from "@/store";
import { isMobile } from "@/service";
import { Loader } from "@googlemaps/js-api-loader";

export default () => {

    return new Promise(resolve => {

        if(localStorage.getItem('auth_token')) {
            store.commit('application/setBootstrapping', true)
        }

        if(isMobile()) {
            store.commit('application/setMobile', true)
            console.log('Mobile device detected')
        }

        const loader = new Loader({
            apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
            version: "weekly",
            libraries: ["places"],
            language: "en"
        });

        loader.load().then(() => resolve())

    })

}